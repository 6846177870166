
import { Component, Vue } from "vue-property-decorator";
import {
  Registration,
  RegistrationComplete,
} from "@/models/utils/user-registration.interface";
import { CustomerType } from "@/models/entities/customer-type.interface";
import { City } from "@/models/entities/city.interface";
import { customerTypes } from "@/services/api/customer-type.service";
import { authenticate } from "@/services/api/authenticate.service";
import { cities } from "@/services/api/cities.service";
import {
  required,
  province,
  email,
  phone,
  fiscalCode,
  passwordLength,
  passwordCheck,
  passwordIsPassword,
  postalCode,
  vatNumber,
  checkEmailExistance,
} from "@/helpers/rules";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import { customers } from "@/services/api/customers.service";
const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class NewAccount extends Vue {
  private registrationFirstStep = {} as Registration;
  private registrationSecondStep = {
    token: null,
    customerTypeID: null,
    firstName: null,
    lastName: null,
    fiscalCode: null,
    businessName: null,
    vatNumber: null,
    city: null,
    province: null,
    address: null,
    postalCode: null,
  } as RegistrationComplete;
  private stepper = 0;
  private businessNameByVat = "";
  private pswCheckString = "";
  private emailNotUsed = true;
  private customerTypes = [] as CustomerType[];
  private cities = [] as City[];
  private postalCodes = [] as { [key: string]: string }[];
  private cityLoading = false;
  private postalCodesLoading = false;
  private validForm = false;
  private isPrivate = false;

  private required = required;
  private province = province;
  private email = email;
  private phone = phone;
  private fiscalCode = fiscalCode;
  private postalCode = postalCode;
  private vatNumber = vatNumber;
  private passwordLength = passwordLength;
  private passwordCheck = passwordCheck;
  private passwordIsPassword = passwordIsPassword;
  private checkEmailExistance = checkEmailExistance;

  private get mobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }

  async created(): Promise<void> {
    this.cityLoading = true;
    const token = this.$route.query.token;
    const userInRegistrationID = this.$route.query.userInRegistrationID;
    if (token && userInRegistrationID) {
      this.stepper = 2;
      await Promise.all([this.getAllCustomerTypes(), this.getAllCities()]);
    } else {
      await Promise.all([this.getAllCustomerTypes(), this.getAllCities()]);
      this.stepper = 0;
    }
  }

  private async getAllCities(): Promise<void> {
    cities
      .getAllCities(false)
      .then((response) => {
        this.cities = response.data;
        this.cityLoading = false;
      })
      .catch(() => {
        snackbarModule.showSnackbar({
          message: "Errore durante il caricamento delle città",
          type: "error",
        });
        setTimeout(() => snackbarModule.hideSnackbar(), 2000);
      });
  }

  private async getCapsByCityId(id: number): Promise<void> {
    cities.getCapListById(id).then((response) => {
      response.data.forEach((cap) => {
        this.postalCodes.push({
          value: cap,
          text: cap,
        });
      });
      this.postalCodesLoading = false;
    });
  }

  private async getAllCustomerTypes(): Promise<void> {
    overlayModule.showOverlay();
    const r = await customerTypes.getAllCustomerType();
    this.customerTypes = r.data.filter(
      (type) =>
        type.customerTypeName == "Privato" || type.customerTypeName == "Azienda"
    );
  }

  private async checkEmailDispatch(): Promise<void> {
    await authenticate
      .checkEmail(this.registrationFirstStep.email)
      .then((response) => {
        this.emailNotUsed = response.data;
      });
  }

  private setCustomerType(id: number, isPrivate: boolean) {
    this.registrationSecondStep.customerTypeID = id;
    this.isPrivate = isPrivate;
  }

  private async checkVatNumberExistence(): Promise<void> {
    const byvat = await customers.getCustomerByVatNumber(
      this.registrationSecondStep.vatNumber
    );
    this.businessNameByVat = byvat.data;
    this.registrationSecondStep.businessName = byvat.data;
  }

  private filterProvince(): void {
    let city = {} as City;
    this.postalCodes = [] as { [key: string]: string }[];
    this.registrationSecondStep.postalCode = "";
    this.registrationSecondStep.province = "";
    city = this.cities.find((city) => {
      if (city.name == this.registrationSecondStep.city) {
        return city;
      }
    });
    if (city) {
      this.registrationSecondStep.province = city.provinceCode;
      this.postalCodesLoading = true;
      this.getCapsByCityId(city.id);
    }
  }

  private async submitFirstStep(): Promise<void> {
    const isValid = (
      this.$refs.registrationFirstStep as Vue & { validate: () => boolean }
    ).validate();
    this.validForm = isValid;
    if (isValid) {
      overlayModule.showOverlay();
      await authenticate.register(this.registrationFirstStep);
      this.stepper++;
    }
  }

  private async submitSecondStep(): Promise<void> {
    const isValid = (
      this.$refs.registrationSecondStep as Vue & { validate: () => boolean }
    ).validate();
    this.validForm = isValid;
    if (isValid) {
      overlayModule.showOverlay();
      this.registrationSecondStep.token = this.$route.query.token as string;
      this.registrationSecondStep.userInRegistrationID = this.$route.query
        .userInRegistrationID as string;
      await authenticate.registerComplete(this.registrationSecondStep);
      snackbarModule.showSnackbar({
        message: "Registrazione completata con successo",
        type: "success",
      });
      setTimeout(() => snackbarModule.hideSnackbar(), 2000);
      this.stepper++;
    }
  }

  private goToLogin() {
    this.$router.push({ name: "login" });
  }

  private goToHomePage() {
    this.$router.push({ name: "home" });
  }
}
